import React from 'react';

import HeaderCommon from 'sections/ScreverOcxProgram/HeaderCommon';
import SurveyIframe from 'sections/ScreverOcxProgram/SurveyIframe';
import SEO from 'sections/Layout/SEO';
import OcxHelmet from 'sections/Layout/SEO/OcxHelmet';

import 'sections/ScreverOcxProgram/common.scss';

const seo = {
  title: 'Screver Boutique Event: Let’s talk & Co-work | Gamification',
  metaDesc:
    "Vote for the topic for Gamification room. We're rolling out the red carpet for a unique three-day get-together in Zurich, exclusively for our special clients",
  image:
    'https://wp.screver.com/wp-content/uploads/2023/08/screver-btq-meta-img.jpg',
  opengraphUrl:
    'https://screver.com/events/screver-btq-program/vote/gamification/',
};

const VoteGamification = () => {
  return (
    <>
      <OcxHelmet />

      <SEO seo={seo} themeColor={'#151517'} />

      <div className="scr-ocx-program scr-btq-event">
        <HeaderCommon
          title="Vote for the topic for Gamification room"
          url="/events/screver-btq-program/"
        />

        <main>
          <div className="ocx-prog-wrap">
            <SurveyIframe
              src={'https://go.screver.com/capptoo/vote-gamification'}
              style={{ height: '850px' }}
            />
          </div>
        </main>
      </div>
    </>
  );
};

export default VoteGamification;
